<template>
  <div class="main">
    <div class="content-container">
<!--    <Head></Head>-->
      <Menu></Menu>
      <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component"  v-if="$route.meta.keepAlive"/>
          </keep-alive>
          <component :is="Component"  v-if="!$route.meta.keepAlive"/>
        </router-view>
      </div>
    <Footer class="bottom-element"></Footer>
  </div>
</template>

<script>
import Head from '../components/common/Head'
import Menu from '../components/common/Menu'
import Footer from '../components/common/Footer'

export default {
    name: 'Main',
    components: {
      Head, Menu, Footer
    }
}
</script>

<style scoped lang="less">
.main{
  width: 100%;
  height: 100vh;
  /* 设置父元素为flex布局, 实现《页面高度不足时，在底部显示；页面高度超出时，随页面滚动》 */
  display: flex;
  /* 设置子元素的排列方向为垂直 */
  flex-direction: column;
  /* 设置子元素在该方向上的对齐方式 */
  justify-content: space-between;

}
.content-container{

}
.bottom-element {

 }
</style>