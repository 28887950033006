<template>
    <div class="content banner">
<!--        <div class="box-title">第十届 “共享杯” 科技资源共享服务创新大赛</div>-->
        <div class="banner-list">
<!--            <img :src="imgUrl" class="link-banner" @click="open" />-->
        </div>
    </div>
</template>

<script>
export default {
    props:{
        imgUrl:{
            type: String,
            default: ''
        },
        linkUrl: {
            type: String,
            default: ''
        }
    },
    methods:{
        open(){
            if(this.linkUrl){
                window.open(this.linkUrl)
            }
        }
    }
}
</script>

<style scoped>
    .banner{
        margin-bottom: 76px;
    }
    .box-title{
        margin-bottom: 18px;
    }
    .link-banner{
        cursor: pointer;
    }
</style>
