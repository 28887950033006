<template>
    <div class="download" v-loading="loading">
        <div class="box-title">下载专区 <a v-if="items.totalPage>1" class="link-more" href="javascript:void(0)"  @click="gotoDownloadList">查看更多 > </a></div>
        <div class="box-content">
            <div class="help">此处只提供基本的竞赛相关的文件的下载，如果同学们要下载资源与数据，请到赛题资源栏下载，谢谢配合！</div>
            <div class="down-item" v-for="(item, index) in items.list" :key="index">
                <a :href="'/core/base/c/download/file?attachId='+item.attachInfoId"  class="down-link">{{item.attachName}}</a>
                <span class="down-date">{{item.createTime}}</span>
            </div>
            
            
        </div>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue'
export default {
    setup(){
        const loading = ref(false)
        const {proxy} = getCurrentInstance();

        const items = ref({
            totalPage: 1,
            total: 1,
            page: 1,
            list:[]
        })

        function getDownloadList(){ //获取下载专区附件信息
            const url = '/core/base/a/attach/filelist?relateType=CPT-DOWNLOAD-ZONE&relateId=CPT-DOWNLOAD-ZONE';
            loading.value = true;
            proxy.$http.post(url, {limit: 6, offset: 0}).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    items.value = res.data.result;
                    if(items.value.total/6 > 1){
                        let page = Number.parseInt(items.value.total / 6);
                        if(items.value.total % 6 > 0){
                            page = page+1;
                        }
                        items.value.totalPage = page;
                    }
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        onMounted(()=>{
            getDownloadList();
        })

        return {loading, items};
    },
    methods:{
        gotoBuilding(){
            this.$router.push({name: 'Building', path: '/building'})
        },
        gotoDownloadList(){
            this.$router.push({name: 'DownloadList', path: '/downloadList'})
        }
    }
}
</script>

<style scoped>
.download{

}
.box-title{
    margin-bottom: 16px;
}
.down-link{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #007EEC;
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 12px;
     max-width: 440px;
    overflow: hidden;
    text-decoration: none;
}
.down-date{
    font-family: SourceHanSansCN-Normal;
    font-size: 14px;
    color: rgba(0,0,0,0.80);
    line-height: 14px;
}
.down-item{
    display: flex;
    justify-content: space-between;
}
.help{
    margin-bottom: 16px;
}
</style>
