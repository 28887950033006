<template>
    <div v-loading="loading">
        <Banner :height="height" :width="width" :imageList="bannerList"></Banner>
        <TopNews @activeChange="activeChange" :baseInfo="baseInfo"></TopNews>
        <div class="content box-news" v-if="active==0">
            <div class="info-box" v-show="false">
                <Info title="动态资讯" more="abc" type="contest"></Info>
            </div>
            <div class="download-box">
                <Download></Download>
            </div>
        </div>
        <!-- <FooterBanner :linkUrl="baseInfo.otherUrl" :imgUrl="baseInfo.bottomUrl"></FooterBanner> -->
    </div>
</template>

<script>
import Head from '../components/common/Head'
import Menu from '../components/common/Menu'
import Footer from '../components/common/Footer'

import Banner from '../components/common/Banner'
import TopNews from '../components/contest/TopNews'
import Info from '../components/Info'
import Download from '../components/Download'
import FooterBanner from '../components/FooterBanner'

import {ref, onMounted, getCurrentInstance} from 'vue'


export default {
    name: 'Contest',
    data(){
        return {
        }
    },
    setup(){
        const {proxy} = getCurrentInstance();
        const width = ref(1440);
        const height = ref(300);
        const active = ref(0);
        const loading = ref(false);
        const baseInfo = ref({bottomUrl:'', topUrl:'',})
        const bannerList = ref([]);
        const bannerLoading = ref(false)

        function fetchBannerList(){ //读取滚动大图
            bannerLoading.value = true;
            var url = "/core/cms/a/webAdInfo/list?noPage=1&typeCode=CPT_HOME_ROLL_IMG";
         
            proxy.$http.post(url).then(res=>{
                bannerLoading.value = false
                if(res.data.state == 0){
                    var list = [];
                    for(let i = 0 ; i < res.data.result.length; i++){
                        const imageLink = proxy.$getImage(res.data.result[i].imgCode);
                        list.push({src: imageLink});
                    }

                    bannerList.value = list;
                }
            }).catch(err=>{
                bannerLoading.value = false
            })
        }


        function getIntroArticle(){    //获取大赛介绍
            const url = '/core/cms/a/articleContent/info?id=CPT-COMPETITION';
            proxy.$http.post(url)
            .then(res=>{
                if(res.data.state == 0){
                    baseInfo.value.dasaijieshao = res.data.result.content;
                }
            }).catch(err=>{
            })
        }
        function getLxwmArticle(){    //获取联系我们
            const url = '/core/cms/a/articleContent/info?id=CPT-CONTACT-US';
            proxy.$http.post(url)
            .then(res=>{
                if(res.data.state == 0){
                    baseInfo.value.lianxiwomen = res.data.result.content;
                }
            }).catch(err=>{
            })
        }


        onMounted(()=>{
            width.value = window.innerWidth;
            height.value = 300 / 1440 * width.value;

            window.onresize=function(){
                width.value = window.innerWidth;
                height.value = 300 / 1440 * width.value;
            }


            fetchBannerList();
            getIntroArticle();
            getLxwmArticle();

        });

        function activeChange(index){
            active.value = index
        }


        

        return {active, width, height,activeChange, baseInfo, loading, bannerList}
    },
    components: {
        Head, Banner, Menu, TopNews,Info, Download, Footer, FooterBanner
    },
    methods:{
        gotoBuilding(){
            this.$router.push({name: 'Building', path: '/building'})
        }
    }
}
</script>

<style lang="less" scoped>
.box-news{
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;
}
.info-box{
    width: 48%;
    .info{
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
}
.download-box{
    width: 100%;
}
</style>
