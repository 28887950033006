<template>
        <div class="info" :style="{padding: isPadding?'14px 16px 14px 18px':''}">
            <div class="box-title" v-if="title">{{title}}
                <a class="link-more" href="javascript:void(0)">查看更多 > </a>
            </div>
            <div class="top-news-box" v-if="item.content && item.content.total > 0">
                <div class="top-date">
                    <div class="day">{{indexDay}}</div>
                    <div class="month">{{indexMonth}}</div>
                </div>
                <div class="top-news">
                    <div class="title" @click="gotoDetail(indexId)" >{{indexTitle}}</div>
                    <div class="desc">
                        {{indexContent}}
                    </div>
                </div>
            </div>
            <div class="box-content" v-if="item.content">
                <template v-for="(newsItem, itemIndex) in item.content.list">
                    <div class="news-item"  v-if="itemIndex>0" :key="newsItem.id">
                        <a href="javascript:void(0)" @click="gotoDetail(newsItem.id)" class="news-link" >{{newsItem.title}}</a>
                        <span class="date">{{newsItem.publishTime.substring(0,10)}}</span>
                    </div>
                </template>
                
            </div>
        </div>
</template>

<script>
import {unref} from 'vue'

export default {
    props:{
        name: {
            type: String,
            default: ''
        },
        isPadding:{
            type: Boolean,
            default: true
        },
        breads:{
            type: Array,
            default: []
        },
        title:{
            type: String,
            default:''
        },
        item:{
            type:Object,
            default: {
                    // name:'',
                    // id: '',
                    // content:{
                    // "total": 3,
                    // "offset": 7,
                    // "limit": 7,
                    // "page": 1,
                    // "list": [
                    //     {
                    //         "adType": "1518073930328129537",
                    //         "adAbstract": "",
                    //         "adTitle": "重点实验室产出报告",
                    //         "id": "1523927354783608834",
                    //         "adDateForMat": "2022-05-10"
                    //     }]
                    //     }
                }
        }
    },
    computed:{
        indexId: function(){
            if(this.item.content && this.item.content.list && this.item.content.list.length > 0){
                return this.item.content.list[0].id;
            }else{
                return '';
            }
        },
        indexTitle: function(){
            if(this.item.content && this.item.content.list && this.item.content.list.length > 0){
                return this.item.content.list[0].title;
            }else{
                return '';
            }
        },
        indexMonth: function(){
            if(this.item.content){
                return this.item.content.list[0].publishTime.substring(0,7);
            }else{
                return '';
            }
        },
        indexDay: function(){
            if(this.item.content){
                return this.item.content.list[0].publishTime.substring(8,10);
            }else{
                return '';
            }
        },
        indexContent: function(){
            if(this.item.content){
                return this.item.content.list[0].intro;
            }else{
                return '';
            }
        }
    },
    methods:{
         gotoDetail(id){
            this.$router.push({name:'NewsDetail', path:'/newsDetail', query:{id:id, name: this.title || this.name }})
        },
    }
}
</script>

<style scoped>

.info{
    width: 100%;
    height: 100%;
    display: inline-block;
    box-sizing:border-box;
    background: #fff;
}
.box-title{
    margin-bottom: 16px;
}
.news-link{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: rgba(0,0,0,0.80);
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 12px;
    max-width: 452px;
    overflow: hidden;
    text-decoration: none;
    margin-right: 20px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.news-link:hover{
    color: #000;
}
.date{
    font-family: SourceHanSansCN-Normal;
    font-size: 14px;
    color: rgba(0,0,0,0.80);
    line-height: 14px;
    min-width: 80px;
    text-align: right;
}
.news-item{
    display: flex;
    justify-content: space-between;
}
.top-news-box{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 12px;
    border-bottom:  1px dashed #E7E7E7;
    margin-bottom: 16px;
}
.top-date{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 68px;
}
.top-date .day{
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: rgba(0,0,0,0.80);
}
.top-date .month{
font-family: PingFangSC-Regular;
font-size: 12px;
color: rgba(98,98,98,0.80);
}
.top-news{
    margin-left: 10px;
    margin-right: 16px;
    flex: 1;
}
.top-news .title{
font-family: PingFangSC-Regular;
font-size: 14px;
color: rgba(0,0,0,0.80);
cursor: pointer;
}
.top-news .desc{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(0,0,0,0.50);
    margin-top: 10px;
    line-height: 20px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 450px;
}

</style>
